import { useEffect, useState } from "react"
import { getCcAvenueEncryptedString, getPaymentProviders, paymentFailOrderCancel } from "../services/services"
import * as language from '../constants/languages'
import { useNavigate, useParams } from "react-router-dom"
import { Spinner } from 'react-bootstrap';

export function PaymentsPage() {
    const { order_id, totalAmount, token, branch_id } = useParams()
    console.log(order_id, token)
    const navigate = useNavigate()

    async function generateHash(dataString, algorithm = 'SHA-256') {
        // Convert the data string to an ArrayBuffer
        const encoder = new TextEncoder();
        const dataBuffer = encoder.encode(dataString);

        // Generate the hash
        const hashBuffer = await window.crypto.subtle.digest(algorithm, dataBuffer);

        // Convert the hash to a hex string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

        return hashHex;
    }

    const handleResponse = (res) => {
        console.log(res, "callback")
        if (typeof res !== "undefined" &&
            typeof res.paymentMethod !== "undefined" &&
            typeof res.paymentMethod.paymentTransaction !== "undefined" &&
            typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined") {
            if (res.paymentMethod.paymentTransaction.statusCode === "0300") {
                // success block
                navigate(`/PaymentSuccess/${order_id}`)
            } else if (res.paymentMethod.paymentTransaction.statusCode === "0398") {
                // initiated block
            } else {
                // error block
                navigate(`/PaymentFail/${order_id}`)
            }
        }
    };
    async function initPayment() {
        // debugger

        const consumerData = {
            deviceId: "WEBSH2", //possible values "WEBSH1" or "WEBSH2"
            token: "",
            returnUrl: "https://api.xeniacatalogue.info/api/WorldLine/Callback", //merchant response page URL
            responseHandler: handleResponse,
            paymentMode: "all",
            merchantLogoUrl: "https://www.paynimo.com/CompanyDocs/company-logo-vertical.png", //provided merchant logo will be displayed
            merchantId: "L995912",
            currency: "INR",
            // consumerId: "c1234",
            txnId: order_id, //Unique merchant transaction ID
            items: [{
                "itemId": "first",
                "amount": totalAmount,
                "comAmt": "0"
            }],
            customStyle: {
                PRIMARY_COLOR_CODE: "#45beaa", //merchant primary color code
                SECONDARY_COLOR_CODE: "#FFFFFF", //provide merchant's suitable color code
                BUTTON_COLOR_CODE_1: "#2d8c8c", //merchant's button background color code
                BUTTON_COLOR_CODE_2: "#FFFFFF" //provide merchant's suitable color code for button text
            },
            accountNo: "", // Add actual value
            consumerMobileNo: "", // Add actual value
            consumerEmailId: "", // Add actual value
            debitStartDate: "", // Add actual value
            debitEndDate: "", // Add actual value
            maxAmount: "", // Add actual value
            amountType: "", // Add actual value
            frequency: "", // Add actual value
            cardNumber: "", // Add actual value
            expMonth: "", // Add actual value
            expYear: "", // Add actual value
            cvvCode: "" // Add actual value
        };

        const salt = "2826959584TDEWOB"; // Replace with the actual salt value provided by Worldline
        const dataString = [
            consumerData.merchantId,
            consumerData.txnId,
            totalAmount,
            consumerData.accountNo,
            consumerData.consumerId,
            consumerData.consumerMobileNo,
            consumerData.consumerEmailId,
            consumerData.debitStartDate,
            consumerData.debitEndDate,
            consumerData.maxAmount,
            consumerData.amountType,
            consumerData.frequency,
            consumerData.cardNumber,
            consumerData.expMonth,
            consumerData.expYear,
            consumerData.cvvCode,
            salt
        ].join('|');

        const hashValue = await generateHash(dataString, 'SHA-512');
        consumerData.token = hashValue
        console.log('Generated Hash:', hashValue);


        const reqJson = {
            features: {
                enableAbortResponse: true,
                enableExpressPay: true,
                enableInstrumentDeRegistration: true,
                enableMerTxnDetails: true,
            },
            consumerData: consumerData
        };

        window.$.pnCheckout(reqJson);
        if (reqJson.features.enableNewWindowFlow) {
            window.pnCheckoutShared.openNewWindow();
        }
    }


    async function cancelOrderOnError() {
        try {
            await paymentFailOrderCancel(language.companyidValue, branch_id, order_id, token)
        } catch (error) {
            console.log(error, 'err')
        }
        navigate(`/PaymentFail/${order_id}`)
    }


    useEffect(() => {
        initPayment()
    }, [])
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spinner animation="border" role="status" />
            </div>
        </>

    )
}

