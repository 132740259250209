import React from 'react'
import '../styles/ErrorPage.css'
import { useNavigate } from 'react-router-dom'
export default function ErrorPage() {
    const navigate= useNavigate()
    return (
        <div className="ErrorContainer">
            <p style={{fontFamily:'Poppins-Regular',fontSize:18,textAlign:'center' }}>An error occured while loading</p>
            <div style={{display:'flex', border:'0.4px solid #fff', padding:7, borderRadius:7}} onClick={() => navigate('/')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.223" height="21.225" viewBox="0 0 19.223 21.225" stroke={ "#ffffff"}>
                    <g id="Icon_feather-home" data-name="Icon feather-home" transform="translate(-3.9 -2.4)">
                        <path id="Path_114" data-name="Path 114" d="M4.5,10.009,13.511,3l9.011,7.009V21.023a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2Z" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                        <path id="Path_115" data-name="Path 115" d="M13.5,28.013V18h6.008V28.013" transform="translate(-2.992 -4.987)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
                    </g>
                </svg>
                <div style={{marginLeft:'0.5rem'}}>Home</div>
            </div>

        </div>
    )
}