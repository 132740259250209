import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './RootPage';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import "../styles/PolicyPages.css"
export default function PrivacyPolicyPage() {
    const { toggleDrawer } = useContext(AuthContext);
    let mediaQueryList = window.matchMedia("(min-width: 992px)");
    const [isLargeDisplay, setisLargeDisplay] = useState(mediaQueryList.matches)

    //check if mobile
    function checkDevice(mql) {
        setisLargeDisplay(mql.matches)
    }
    useEffect(() => {

        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", checkDevice)
        }
        else {
            mediaQueryList.addListener(checkDevice)
        }
        return () => {
            if (mediaQueryList.addEventListener) {
                mediaQueryList.removeEventListener("change", checkDevice)
            } else {
                mediaQueryList.removeListener(checkDevice)
            }
        }
    }, [])
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 15, paddingBlock: '2rem' }}>
                {
                    !isLargeDisplay &&
                    <div style={{}} >
                        <img src={DrawerIcon} style={{ width: 20, height: 15 }} onClick={() => toggleDrawer()} />
                    </div>}
                <p className='headerText'>PRIVACY POLICY</p>

            </div>
            <p className='bodyText'>
                <p>
                    The terms &quot;We&quot; / &quot;Us&quot; / &quot;Our&quot;/”Company” individually and collectively refer to ULCCS IT Infrastructure
                    Private Limited and the terms &quot;Visitor” ”User” refer to the users.
                </p>
                <p>
                    This Privacy Policy is an electronic record in the form of an electronic contract formed under the provisions of
                    Information Technology Act, 2000 with all its subsequent amendments and the rules made thereunder
                    pertaining to electronic documents / records in various statutes as amended by the Information Technology
                    Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature.
                </p>
                <p>
                    This Privacy Policy is a legally binding document between the Visitor/User of the Website and the Company.
                    The terms of this Privacy Policy will be effective upon your acceptance of the same (directly or indirectly in
                    electronic form, by clicking on the “I accept tab” or by use of the website or by other means) and will govern
                    the relationship between you and the Company for your use of the website <b>“Website”.</b>
                </p>
                <p>
                    This document is published and shall be construed in accordance with the provisions of the Information
                    Technology (reasonable security practices and procedures and sensitive personal data of information) rules,
                    2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for collection,
                    use, storage and transfer of sensitive personal data or information.
                </p>
                <p>
                    Please read this Privacy Policy carefully. By using the Website, you indicate that you understand, agree and
                    consent to this Privacy Policy. You may immediately exit from the Website without using it If you do not
                    agree with the terms of this Privacy Policy.
                </p>
                <p>
                    By providing us your Information or by making use of the facilities provided by the Website, You hereby
                    consent to the collection, storage, processing and transfer of any or all of Your Personal Information and
                    Non-Personal Information by us as specified under this Privacy Policy. You further agree that such
                    collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to you or
                    any other person.
                </p>
                <b>USER INFORMATION</b>
                <p>
                    Personal identifiable information (“Personal Information”) such as - your name, address, telephone number, e-
                    mail addresses, and any other information you may voluntarily provide. You will have the choice whether or not
                    to disclose such Personal Information in the above activities; however, some parts of the Site and some services
                    may be more difficult or impossible to use if you choose not to disclose Personal Information.
                </p>
                <p>
                    To avail certain services on our Websites, users are required to provide certain information for the registration
                    process namely: - a) name, b) email address, c) sex, d) age, e) PIN code, f) credit card or debit card details g)
                    medical records and history h) sexual orientation, i) biometric information, j) password etc., and / or your
                    occupation, interests, and the like. The Information as supplied by the users enable us to improve our sites
                    and provide you the most user-friendly experience.
                </p>
                <p>
                    All required information is service dependent and we may use the above said user information to, maintain,
                    protect, and improve its services (including advertising services) and for developing new services.
                </p>
                <p>
                    Such information will not be considered as sensitive if it is freely available and accessible in the public domain
                    or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.
                </p>
                <b>COOKIES</b>
                <p>
                    To improve the responsiveness of the sites for our Users, we may use &quot;cookies&quot;, or similar electronic tools to
                    collect information to assign each Visitor a unique, random number as a User Identification (User ID) to
                    understand the user&#39;s individual interests using the Identified Computer. Unless you voluntarily identify
                    yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a
                    cookie to your computer. The only personal information a cookie can contain is information you supply (an

                    example of this is when you ask for our Personalised Horoscope). A cookie cannot read data off your hard
                    drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process
                    that we do not control.
                </p>
                <p>
                    Our web servers automatically collect limited information about your computer&#39;s connection to the Internet,
                    including your IP address, when you visit our site. (Your IP address is a number that lets computers attached
                    to the Internet know where to send you data -- such as the web pages you view.) Your IP address does not
                    identify you personally. We use this information to deliver our web pages to you upon request, to tailor our site
                    to the interests of our users, to measure traffic within our site and let advertisers know the geographic
                    locations from where our visitors come.
                </p>

                <b>LINKS TO THE OTHER SITES</b>
                <p>
                    Our policy discloses the privacy practices for our own web site only. Our site provides links to other websites
                    also that are beyond our control. We shall in no way be responsible in way for your use of such sites.
                </p>
                <b>INFORMATION SHARING</b>
                <p>
                    We share the sensitive personal information to any third party without obtaining the prior consent of the user
                    in the following limited circumstances:
                </p>
                <p>
                    (a) When it is requested or required by law or by any court or governmental agency or authority to disclose,
                    for the purpose of verification of identity, or for the prevention, detection, investigation including cyber
                    incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and
                    belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the
                    applicable laws and regulations.
                </p>
                <p>
                    (b) We propose to share such information within its group companies and officers and employees of such
                    group companies for the purpose of processing personal information on its behalf. We also ensure that these
                    recipients of such information agree to process such information based on our instructions and in compliance
                    with this Privacy Policy and any other appropriate confidentiality and security measures.
                </p>
                <b>INFORMATION SECURITY</b>
                <p>
                    We take appropriate security measures to protect against unauthorized access to or unauthorized alteration,
                    disclosure or destruction of data. These include internal reviews of our data collection, storage and
                    processing practices and security measures, including appropriate encryption and physical security
                    measures to guard against unauthorized access to systems where we store personal data.
                </p>
                <p>
                    All information gathered on our Website is securely stored within our controlled database. The database is
                    stored on servers secured behind a firewall; access to the servers is password-protected and is strictly
                    limited. However, as effective as our security measures are, no security system is impenetrable. We cannot
                    guarantee the security of our database, nor can we guarantee that information you supply will not be
                    intercepted while being transmitted to us over the Internet. And, of course, any information you include in a
                    posting to the discussion areas is available to anyone with Internet access.
                </p>
                <p>
                    However the internet is an ever evolving medium. We may change our Privacy Policy from time to time to
                    incorporate necessary future changes. Of course, our use of any information we gather will always be
                    consistent with the policy under which the information was collected, regardless of what the new policy may
                    be.
                </p>
                <p>
                    <b>Grievance Redressal</b>
                </p>
                <p>
                    Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or
                    breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned
                    below via in writing or through email signed with the electronic signature to subin@ulcyberpark.com
                    (&quot;Grievance Officer&quot;).
                    Mr. Subin 
                    <b> (Grievance Officer)</b>
                </p>

            </p>
        </div >
    )
} 