import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './RootPage';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import "../styles/PolicyPages.css"
export default function TermsConditionsPage() {
    const { toggleDrawer } = useContext(AuthContext);
    let mediaQueryList = window.matchMedia("(min-width: 992px)");
    const [isLargeDisplay, setisLargeDisplay] = useState(mediaQueryList.matches)

    //check if mobile
    function checkDevice(mql) {
        setisLargeDisplay(mql.matches)
    }
    useEffect(() => {

        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", checkDevice)
        }
        else {
            mediaQueryList.addListener(checkDevice)
        }
        return () => {
            if (mediaQueryList.addEventListener) {
                mediaQueryList.removeEventListener("change", checkDevice)
            } else {
                mediaQueryList.removeListener(checkDevice)
            }
        }
    }, [])
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 15, paddingBlock: '2rem' }}>
                {
                    !isLargeDisplay &&
                    <div style={{}} >
                        <img src={DrawerIcon} style={{ width: 20, height: 15 }} onClick={() => toggleDrawer()} />
                    </div>}
                <p className='headerText'>TERMS AND CONDITIONS</p>

            </div>
            <p className='bodyText'>
                <p>
                    The terms &quot;We&quot; / &quot;Us&quot; / &quot;Our&quot;/”Company” individually and collectively refer to ULCCS IT Infrastructure
                    Private Limited and the terms &quot;Visitor” ”User” refer to the users.
                </p>
                <p>
                    This document establishes the Terms and Conditions which the Visitor on this Website or the User of this
                    Website shall strictly follow. On marking acceptance shall confirm that the Visitor/User has read and
                    understood the terms and conditions herein. If the Visitor/User do not accept the Terms and Conditions
                    stated here, he shall immediately log out from this site. These terms and conditions are acceptable to all
                    transactions made on this Website. We reserve our right to update or amend the terms and conditions
                    without notice from time to time. All Visitors/Users are advised to re-appraise the terms and conditions
                    each time they log in on this Website.
                </p>

                <b>USE OF CONTENT</b>
                <p>
                    All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combinations
                    thereof, appearing in this site, except as otherwise noted, are properties owned and/or used under
                    licence, by the business and / or its associate entities who feature on this Website. The use of these
                    properties or any other content on this site, except as provided in these terms and conditions or in the site
                    content or as authorized in writing, shall attract the provisions of respective laws. Unauthorized use is
                    strictly prohibited.
                </p>
                <p>
                    The Visitors on this Website or the Users of this Website shall not sell or modify the content of this
                    Website or reproduce, display, publicly perform, transfer or otherwise use the materials in any way for any
                    purpose without the written permission from the respective owners of the content.
                </p>
                <b>ACCEPTABLE WEBSITE USE</b>
                <p>
                    Visitors/Users shall not violate or attempt to violate the security of the Web site, including, without
                    limitation, (1) accessing data not intended for such Visitor/User or logging into a server or account which
                    the User is not authorised to access, (2) attempting to probe, scan or test the vulnerability of a system or
                    network or to breach security or authentication measures without proper authorisation in writing, (3)
                    attempting to hinder or interfere with service to any User, host or network, including, without limitation, via
                    means of submitting a virus or &quot;Trojan horse&quot; to the Website, overloading, &quot;flooding&quot;, &quot;mail bombing&quot; or
                    &quot;crashing&quot; or by using any other method, or (4) sending unsolicited electronic mail, including promotions
                    and/or advertising of products or services. Violations of system or network security may result in civil or
                    criminal liability.
                </p>
                <p>
                    Visitors/Users may not use the Web Site in order to transmit, distribute, store or destroy material (a) that
                    could constitute or encourage conduct that would be considered a criminal offence or violate any
                    applicable law or regulation, (b) in a manner that will infringe the copyright, trademark, trade secret or
                    other intellectual property rights of others or violate the privacy or publicity of other personal rights of
                    others, or (c) that is libellous, defamatory, pornographic, profane, obscene, threatening, abusive or hateful
                    or any other objectionable and/or offensive.
                </p>

                <b>INDEMNITY</b>
                <p>
                    The User unilaterally agree to indemnify and continue to indemnify and hold harmless, without objection,
                    the owner of this Website, the Company, its officers, directors, employees and agents from and against
                    any claims, actions and/or demands and/or liabilities and/or losses and/or damages whatsoever arising
                    from or resulting from their misuse of https://ulccsitinfrastructure.xeniacatalogue.info/ or their breach of
                    the terms .
                </p>
                <b>LIABILITY</b>
                <p>
                    This Website Visitors/Users agree that neither  Company  nor its group companies, directors, officers or
                    employee shall be liable for any direct or/and indirect or/and incidental or/and special or/and
                    consequential or/and exemplary damages, resulting from the use or/and the inability to use the service
                    or/and for cost of procurement of substitute goods or/and services or resulting from any goods or/and
                    data or/and information or/and services purchased or/and obtained or/and messages received or/and
                    transactions entered into through or/and from the service or/and resulting from unauthorized access to
                    or/and alteration of user&#39;s transmissions or/and data or/and arising from any other matter relating to the
                    service, including but not limited to, damages for loss of profits or/and use or/and data or other intangible,
                    even if  Company  has been advised of the possibility of such damages.
                </p>
                <p>
                    Visitors/Users further agree that  Company  shall not be liable for any damages arising from interruption,
                    suspension or termination of service, including but not limited to direct or/and indirect or/and incidental
                    or/and special consequential or/and exemplary damages, whether such interruption or/and suspension
                    or/and termination was justified or not, negligent or intentional, inadvertent or advertent.
                </p>
                <p>
                    Visitors/Users agree that  Company  shall not be responsible or liable to User, or anyone, for the
                    statements or conduct of any third party of the service. In no event shall Company&#39;s total liability to the
                    Visitor/User for all damages or/and losses or/and causes of action exceed the amount paid by the
                    Visitior/User to  Company , if any, that is related to the cause of action.
                </p>
                <b>DISCLAIMER OF CONSEQUENTIAL DAMAGES</b>
                <p>
                    In no event shall Company or any parties, organizations or entities associated with the corporate brand
                    name of ours or otherwise, mentioned at this Website be liable for any damages whatsoever (including,
                    without limitations, incidental and consequential damages, lost profits, or damage to computer hardware
                    or loss of data information or business interruption) resulting from the use or inability to use the Website
                    and the Website material, whether based on warranty, contract, tort, or any other legal theory, and
                    whether or not, such organization or entities were advised of the possibility of such damages.
                </p>
            </p>
        </div>
    )
} 