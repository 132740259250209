import { Component } from 'react';
import * as language from '../constants/languages';
import OfferListComponent from '../components/OfferList';
import SpinnerLoader from '../components/SpinnerLoader';
import { getOffers } from '../services/services';
import HeaderCommon from '../components/HeaderCommon'

class OffersPage extends Component {


    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            selectedIndex: 0,
            offersData: [],
        };
    }

    componentDidMount() {
        this.getOffers();

    }


    async getOffers() {
        this.branchId = localStorage.getItem('storeId');
        this.token = localStorage.getItem('access_token');
        await getOffers(language.companyidValue, this.branchId, this.token).then((offersData) => {
            if (offersData.status === "success" && offersData.data.length != 0) {
                this.setState({
                    isLoading: false,
                    isOffer: true,
                    offersData: offersData.data

                })
            } else {
                this.setState({
                    isLoading: false,
                    isOffer: false
                })
            }
        })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    isOffer: false
                })
            })

    }
    render() {
        return (
            <div style={{ flex: 1, }}>
                <HeaderCommon

                />
                {
                    this.state.isLoading == true ?
                        <SpinnerLoader />
                        :
                        <div >
                            <div style={{
                                paddingTop: 20,
                                paddingBottom: 10,
                                marginBlock: 20,
                                borderBottomColor: '#f1f2f6',
                                borderBottomWidth: 2,
                                marginBottom: 5,
                                borderBottomStyle: 'solid'
                            }}>
                                <h5 style={{ textAlign: 'center' }}>{language.offers}</h5>
                            </div>
                            <div style={{ flex: 1 }}>
                                {
                                    this.state.isOffer == false ?
                                        <div style={{ marginTop: 20, flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center', height: '10vh' }}>
                                            <p>No Offers</p>
                                        </div>
                                        :
                                        <OfferListComponent
                                            navigation={this.props.navigation}
                                            data={this.state.offersData}
                                            setLoading={(value) => this.setState({ isLoading: value })}
                                        />

                                }
                            </div>

                        </div>

                }
            </div>
        )
    }

}



export default function (props) {
    return <OffersPage {...props} />;

}
