import React, { useState, useEffect } from 'react';
import * as language from '../constants/languages';
import { getBranches } from '../services/services';

export default function StoreChoose() {
    // get the branch data
    async function getBranchesFunction() {

        await getBranches(language.companyidValue).then((branches) => {
            if (branches.status === 'success') {
                        const event = new CustomEvent('storeSelected', { detail: branches.data[branches.data.length-1].branchName });;
                        localStorage.setItem('storeId', JSON.stringify(branches.data[branches.data.length-1].branchId));
                        localStorage.setItem('storeName', branches.data[0].branchName);
                        window.dispatchEvent(event)
            }

        })
            .catch((error) => {
                // console.log(error, 'branch error');
            })

    }
    useEffect(() => {
        getBranchesFunction()
    }, [])
}