import { useReducer, createContext, useMemo, useState, useEffect } from 'react'
import { Outlet, useLocation } from "react-router-dom";
import BottomNavigator from "../components/BottomNavigator";
import SideBar from '../components/SideBar';
import HeaderLarge from '../components/HeaderLarge';
import FooterComponent from '../components/FooterComponent';
import ScrollToTop from '../components/ScrollToTop';
import StoreChoose from './StoreChoose';
import SpinnerLoader from '../components/SpinnerLoader';
import PaymentSuccessPage from './PaymentSuccessPage';
export const AuthContext = createContext();

export default function RootPage() {
  let mediaQueryList = window.matchMedia("(min-width: 992px)");
  const [isLargeDisplay, setisLargeDisplay] = useState(mediaQueryList.matches)
  const [storeChoosed, setstoreChoosed] = useState()

  const readDataevent = (data) => {
    storeSelected(data.detail)
  }

  // storeSelected
  async function storeSelected(store) {
    if (store == null) {
      localStorage.removeItem('storeChooseFlag')
    } else {
      localStorage.setItem('storeChooseFlag', store)
    }
    setstoreChoosed(store)

  }

  // check if store choosed
  async function checkStoreChoosed() {
    const value = localStorage.getItem('storeChooseFlag')

    setstoreChoosed(value)
  }
  useEffect(() => {
    checkStoreChoosed();
    window.addEventListener('storeSelected', readDataevent)
   
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener("change", checkDevice)
      return () => {
        mediaQueryList.removeEventListener("change", checkDevice)
      window.removeEventListener('storeSelected', readDataevent);

      }
    } else {
      mediaQueryList.addListener(checkDevice)
      return () => {
        mediaQueryList.removeListener(checkDevice)
      window.removeEventListener('storeSelected', readDataevent);

      }
    }

    

  }, [])
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'TOGGLE_DRAWER':
          return {
            ...prevState,
            openDrawer: !prevState.openDrawer
          };

      }
    },
    {
      openDrawer: false,

    }
  );
  const authContext = useMemo(
    () => ({
      toggleDrawer: async (data) => {
        dispatch({ type: 'TOGGLE_DRAWER' });
      },

    }),
    []
  );
  //check if mobile
  function checkDevice(mql) {
    setisLargeDisplay(mql.matches)
  }
  return (
    
    storeChoosed ?
      <AuthContext.Provider value={authContext}>
        <ScrollToTop />

        {!isLargeDisplay && <SideBar drawerState={state.openDrawer} />}
        {isLargeDisplay && <HeaderLarge />}
        <Outlet />
        {!isLargeDisplay && <BottomNavigator />}
        {
          isLargeDisplay &&
          <FooterComponent />

        }

      </AuthContext.Provider>
      :
      <div className='SpinnerContainer'>
        <StoreChoose />
        <SpinnerLoader />
      </div>
  )
}