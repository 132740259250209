import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './RootPage';
import DrawerIcon from '../assets/svg/header/drawericon.svg';
import "../styles/PolicyPages.css"
export default function RefundPolicyPage() {
    const { toggleDrawer } = useContext(AuthContext);
    let mediaQueryList = window.matchMedia("(min-width: 992px)");
    const [isLargeDisplay, setisLargeDisplay] = useState(mediaQueryList.matches)

    //check if mobile
    function checkDevice(mql) {
        setisLargeDisplay(mql.matches)
    }
    useEffect(() => {

        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", checkDevice)
        }
        else {
            mediaQueryList.addListener(checkDevice)
        }
        return () => {
            if (mediaQueryList.addEventListener) {
                mediaQueryList.removeEventListener("change", checkDevice)
            } else {
                mediaQueryList.removeListener(checkDevice)
            }
        }
    }, [])
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 15, paddingBlock: '2rem' }}>
                {
                    !isLargeDisplay &&
                    <div style={{}} >
                        <img src={DrawerIcon} style={{ width: 20, height: 15 }} onClick={() => toggleDrawer()} />
                    </div>}
                <p className='headerText'>REFUND AND CANCELLATION POLICY</p>

            </div>
            <p className='bodyText'>
                An order can be cancelled before payment. Once the order is processed with payment
                no cancellation request will be entertained. In the event of any complaints or issue while
                making payments the amount credited to our accounts will be refund back to the original
                payment source within 7 days without any interest.
            </p>
        </div>
    )
} 