import React from 'react';
import ProductsListingComponent from '../components/ProductListingComponent';
import SpinnerLoader from '../components/SpinnerLoader';
import SearchHeader from '../components/SearchHeader';
import * as language from '../constants/languages';
import { useParams } from 'react-router-dom';
import { getSearchProducts, } from '../services/services';
import ArrowLeft from '../assets/svg/keyboardright.svg'
import ArrowRight from '../assets/svg/keyboardleft.svg'
class SearchPage extends React.PureComponent {
    constructor(props) {
        super(props)
        this.mediaQueryList = window.matchMedia("(min-width: 992px)");

        this.token = localStorage.getItem('access_token');
        this.branchId = localStorage.getItem('storeId');
        this.isLogged = localStorage.getItem('logged');
        this.state = {
            gridView: true,
            viewSelect: 1,
            filterModalVisible: false,
            productData: null,
            isLoading: true,
            filterData: null,
            searchData: null,
            spinnerOverlay: false,
            filterApplied: false,
            refresh: false,
            page: 1,
            hasNext: false,
            hasPrevious: false,
            count: 0,
            isLargeDisplay: this.mediaQueryList.matches
        }
        this.checkDevice = this.checkDevice.bind(this)
    }

    getPageSize() {

        return 1;

    }
    //check if mobile
    checkDevice(mql) {
        this.setState({
            isLargeDisplay: mql.matches,
        })
    }
    componentDidMount() {
        if (this.mediaQueryList.addEventListener) {
            this.mediaQueryList.addEventListener("change", this.checkDevice)
        } else {
            this.mediaQueryList.addListener(this.checkDevice)
        }
        this.getSearchName(this.props.searchname)


    }
    componentDidUpdate(prevProps) {
        if (prevProps.searchname != this.props.searchname) {
            this.getSearchName(this.props.searchname)
        }
    }

    // search product name
    async getSearchName(name) {
        this.searchName = name;
        await this.setState({
            isLoading: true,
            page: 1
        })
        this.getSearchProducts(name, '', '', '', '', '', this.getPageSize());
    }

    // get search products
    async getSearchProducts(productName, category, brand, rating, lowPrice, highPrice, page) {

        this.cart = localStorage.getItem('cartItems');
        await getSearchProducts(language.companyidValue, productName, category, brand, rating, lowPrice, highPrice, this.branchId, this.token, page).then((searchProduct) => {
            // console.log(searchProduct, 'searchProduct in search screen');
            if (searchProduct.status === "success") {

                this.setState({
                    isLoading: false,
                    refresh: false,
                    searchData: searchProduct.data,//searchProduct.data,
                    spinnerOverlay: false,
                    hasNext: searchProduct.hasNext,
                    hasPrevious: searchProduct.hasPrevious,
                    count: searchProduct.totalCount,
                    currentPage: searchProduct.currentPage
                })



            } else {
                this.setState({
                    isLoading: true,
                    refresh: false,
                    spinnerOverlay: false,
                    hasNext: false,
                    hasPrevious: false,
                    count: 0,
                    currentPage: undefined
                })
            }

        })
            .catch((error) => {
                // console.log(error, 'error');
                this.setState({
                    isLoading: true,
                    refresh: false,
                    spinnerOverlay: false,
                    hasNext: false,
                    hasPrevious: false,
                    count: 0,
                    currentPage: undefined
                })
            })
    }

    // product added to cart
    async itemAddedToCart(added, productData) {
        await this.setState({
            spinnerOverlay: true,
        })
        if (added == 1) { //product added
            if (typeof this.searchName === "undefined") {
                if (this.state.filterApplied == true) {
                    this.getSearchProducts('', this.state.appliedFilters.category, this.state.appliedFilters.brand, this.state.appliedFilters.rating, this.state.appliedFilters.lowRange, this.state.appliedFilters.highRange, this.getPageSize());
                } else {
                    this.getSearchProducts('', "", "", "", '', '', this.getPageSize());
                }
            } else {
                if (this.state.filterApplied == true) {
                    this.getSearchProducts(this.searchName, this.state.appliedFilters.category, this.state.appliedFilters.brand, this.state.appliedFilters.rating, this.state.appliedFilters.lowRange, this.state.appliedFilters.highRange, this.getPageSize());
                } else {
                    this.getSearchProducts(this.searchName, "", "", "", '', '', this.getPageSize());
                }

            }
        }

    }

    // opne filter
    async openFiler() {
        this.getFilterData(language.companyidValue, '', '');

    }

    // // get the filterData
    // async getFilterData(companyId, cotegoryId, brandId) {
    // 	await this.setState({
    // 		spinnerOverlay: true
    // 	})
    // 	await getFilterDetails(companyId, cotegoryId, brandId).then((filterData) => {
    // 		// console.log(filterData, 'filterData');
    // 		if (filterData.status == "success") {
    // 			this.setState({
    // 				filterModalVisible: !this.state.filterModalVisible,
    // 				filterData: filterData.data,
    // 				spinnerOverlay: !this.state.spinnerOverlay,
    // 			})
    // 		}
    // 	})
    // 		.catch((error) => {
    // 			console.log(error, 'error filterData');
    // 		})
    // }

    // get filters
    async filterSubmit(category, brand, rating, lowPrice, highPrice) {
        if (category.length == 0) {
            category = ''
        } else {
            category = category
        }
        if (brand.length == 0) {
            brand = ''
        } else {
            brand = brand
        }
        if (rating.length == 0) {
            rating = ''
        } else {
            rating = rating
        }
        await this.setState({
            filterModalVisible: !this.state.filterModalVisible,
            filterApplied: true,
            page: 1,
            appliedFilters: {
                category: category,
                brand: brand,
                rating: rating,
                lowRange: lowPrice,
                highRange: highPrice
            }
        })
        this.getSearchProducts(this.searchName, category, brand, rating, lowPrice, highPrice, this.getPageSize());
    }

    async previousPage() {

        this.setState({
            page: this.state.page - 1,
            spinnerOverlay: true,
        }, () => {
            if (this.state.filterApplied == true) {
                this.getSearchProducts(this.searchName, this.state.appliedFilters.category, this.state.appliedFilters.brand, this.state.appliedFilters.rating, this.state.appliedFilters.lowRange, this.state.appliedFilters.highRange, this.state.page);
            } else {
                this.getSearchProducts(this.searchName, "", "", "", '', '', this.state.page);
            }
        })


    }
    async nextPage() {

        this.setState({
            page: this.state.page + 1,
            spinnerOverlay: true,
        }, () => {
            if (this.state.filterApplied == true) {
                this.getSearchProducts(this.searchName, this.state.appliedFilters.category, this.state.appliedFilters.brand, this.state.appliedFilters.rating, this.state.appliedFilters.lowRange, this.state.appliedFilters.highRange, this.state.page);
            } else {
                this.getSearchProducts(this.searchName, "", "", "", '', '', this.state.page);
            }
        })


    }

    componentWillUnmount() {
        if (this.mediaQueryList.addEventListener) {
            this.mediaQueryList.removeEventListener("change", this.checkDevice)
        }
        else {
            this.mediaQueryList.removeListener(this.checkDevice)
        }
        localStorage.removeItem('cateFilter');
        localStorage.removeItem('brandFilter');
        localStorage.removeItem('ratingFilter');
        localStorage.removeItem('priceApplied');
        localStorage.removeItem('lowRange');
        localStorage.removeItem('highRange');
    }


    render() {
        return (
            <div >
                {
                    !this.state.isLargeDisplay && <SearchHeader
                        value={this.props.searchname}
                        searchName={(name) => this.getSearchName(name)}
                    />}
                <div style={{ flex: 1 }}>

                    <div style={{ flex: 1, marginTop: 5 }}>
                        {
                            this.state.isLoading == true ?
                                <SpinnerLoader />
                                :
                                <div >
                                    {
                                        this.state.searchData.length == 0 ?
                                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20, padding: 10 }}>
                                                <p style={{ textAlign: 'center', }}>No {this.searchName} Available</p>
                                            </div>
                                            :
                                            <div style={{ flex: 1 }}>
                                                <div style={{ flex: 1 }}


                                                >
                                                    <ProductsListingComponent
                                                        viewSelect={this.state.viewSelect}

                                                        productData={this.state.searchData}
                                                        addedItemToCart={(added, productData) => this.itemAddedToCart(added, productData)}
                                                    />
                                                    {
                                                        (this.state.searchData?.length == 0)
                                                            ? null
                                                            :
                                                            // this.state.currentPage == 1 || !this.state.currentPage ?
                                                            //     null :
                                                            (this.state.hasNext || this.state.hasPrevious) &&

                                                            (<div style={{ justifyContent: 'center', alignItems: 'flex-end', marginBottom: 20, marginHorizontal: 10, marginTop: 40, flex: 1, display: 'flex', }}>

                                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                                    {
                                                                        <div onClick={() => this.previousPage()} style={{ visibility: this.state.hasPrevious ? 'visible' : 'hidden' }}>
                                                                            <img src={ArrowLeft} size={30} />
                                                                        </div>
                                                                    }
                                                                    <div style={{ backgroundColor: '#1d4e4b', borderRadius: 5, alignItems: 'center', display: 'flex', padding: 10 }}>

                                                                        <p style={{ color: '#ffffff', margin: 0 }}>
                                                                            {this.state.currentPage}
                                                                        </p>

                                                                    </div>
                                                                    {
                                                                        <div onClick={() => this.nextPage()} style={{ visibility: this.state.hasNext ? 'visible' : 'hidden' }}>
                                                                            <img src={ArrowRight} />
                                                                        </div>
                                                                    }
                                                                </div>


                                                            </div>)
                                                    }

                                                </div>

                                            </div>

                                    }

                                </div>
                        }
                    </div>
                </div>


                {/* <Modal
					transparent={true}
					hardwareAccelerated={true}
					visible={this.state.filterModalVisible}
					onRequestClose={() => this.setState({ filterModalVisible: !this.state.filterModalVisible })}
					animationType={'slide'}>
					<FilterModalComponent
						closeFilter={() => this.setState({ filterModalVisible: !this.state.filterModalVisible })}
						filterData={this.state.filterData}
						filterSubmit={(category, brand, rating, lowPrice, highPrice) => this.filterSubmit(category, brand, rating, lowPrice, highPrice)}
					/>
				</Modal>
				<Spinner
					visible={this.state.spinnerOverlay}
					overlayColor={this.props.colorScheme === 'dark' ? "rgb(0,0,0,0.5)" : "rgba(0,0,0,0.12)"}
					color={this.props.colorScheme === 'dark' ? 'white' : "#333"}
				/> */}
            </div>



        )
    }



}


export default function (props) {
    let { search_name } = useParams()
    return <SearchPage {...props} searchname={search_name} />;
}